/**
 * big title
 */
.big_title
  line-height 1
  font-family montserrat_font
  font-weight 700
  fz--lg(8)
  color gray_01
  text-align center

  +mq(sp)
    fz(3.6)
    padding 0 15px

  span
    line-height 1.7
    font-family gothic_font
    font-weight 400
    fz--lg(3.5)
    color black_01
    display block
    margin-top px--lg(30)

    +mq(sp)
      fz(1.6)
