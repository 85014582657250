.ml_visual
  width 100%
  max-height px--lg(600)
  bg("")
  color white_01
  padding px--lg(95) px--lg(185) px--lg(185)

  +mq(sp)
    padding 50px 15px
    text-align center

  &__lead
    background linear-gradient(transparent 65%, violet_02 0)
    line-height 1
    fz--lg(5.5)
    display inline-block
    padding-bottom 5px
    margin px--lg(35) px--lg(45)
    position relative

    +mq(sp)
      fz(1.8)
      margin 15px 20px

    &::before, &::after
      content ""
      width px--lg(271)
      height px--lg(88)
      bg("", false)
      position absolute

      +mq(sp)
        width 88px
        height 29px

    &::before
      setBg("common/ico_bracket_01.png")
      top px--lg(-35)
      left px--lg(-45)

      +mq(sp)
        top -15px
        left -20px

    &::after
      setBg("common/ico_bracket_02.png")
      bottom px--lg(-35)
      right px--lg(-45)

      +mq(sp)
        bottom -15px
        right -20px

  &__text
    fz--lg(3)
    margin-top px--lg(40)

    +mq(sp)
      fz(1.4)

  &__btn
    width px--lg(200)
    border 2px solid white_01
    border-radius 10px
    margin px--lg(70) 0 0 px--lg(270)
    transition all .3s

    +mq(sp)
      margin 45px auto 0

    a
      fz--lg(2.5)
      color white_01
      text-align center
      display block
      transition all .3s

      +mq(sp)
        fz(1.4)

    &:hover
      background-color white_01

      a
        color blue_01
