/**
 * br
 */
bd = mq_config.bd

if (bd == "sp")
  .br--sp
    +mq(tab)
      display none

  .br--tab
    display none

    +mq(tab)
      display block

    +mq(pc)
      display none

  .br--pc
    display none

    +mq(pc)
      display block

else if (bd == "pc")
  .br--pc
    +mq(tab)
      display none

  .br--tab
    display none

    +mq(tab)
      display block

    +mq(sp)
      display none

  .br--sp
    display none

    +mq(sp)
      display block

else
  background-color red
