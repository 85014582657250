.ml_mv
  bg("")
  text-align center
  padding px--lg(220) 0

  +mq(sp)
    padding 200px 0 140px

  &__title
    background linear-gradient(transparent 65%, violet_02 0)
    line-height 1
    font-family skia_font
    fz--lg(7)
    color white_01
    display inline-block
    padding 0 px--lg(20)
    margin px--lg(30) 0
    position relative

    +mq(sp)
      fz(3.6)

    &::before, &::after
      content ""
      width px--lg(127)
      height px--lg(87)
      bg("", false)
      position absolute

      +mq(sp)
        width 63px
        height 44px

    &::before
      setBg("common/ico_bracket_03.png")
      top px--lg(-30)
      left px--lg(-50)

    &::after
      setBg("common/ico_bracket_04.png")
      bottom px--lg(-30)
      right px--lg(-50)

  &__sub_title
    fz--lg(2.5)
    color white_01
    margin-top px--lg(25)

    +mq(sp)
      fz(1.8)
