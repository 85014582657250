.ml_block
  margin-top px--lg(70)

  +mq(sp)
    padding 0 15px
    margin-top 45px

  &__title
    line-height 1
    font-family montserrat_font
    fz--lg(5.5)
    text-align center

    +mq(sp)
      line-height 1.5
      fz(2.4)

    span
      font-family gothic_font
      fz--lg(2.5)
      display block
      margin-top px--lg(40)

      +mq(sp)
        fz(1.6)
        margin-top 10px

  &__disc
    max-width px--lg(1600)
    border 1px solid black_01
    padding 15px 20px
    margin px--lg(30) auto 0

    +mq(sp)
      max-width 345px
      margin-top 20px

  .wrapper
    max-width px--lg(1600)
    flex(hor)
    margin px--lg(70) auto 0

    +mq(sp)
      max-width 345px
      display block
      margin-top 45px

  &__item
    width 31.25%
    border 1px solid black_01
    border-radius 10px
    text-align center
    flex(ver)
    padding px--lg(30)

    +mq(sp)
      width 100%
      padding 20px

    &:nth-child(n + 4)
      margin-top px--lg(60)

      +mq(sp)
        margin-top 0

    +mq(sp)
      &:nth-child(n + 2)
        margin-top 30px

    &:nth-child(4)
      margin-left px--lg(270)

      +mq(sp)
        margin-left 0

    &:nth-child(5)
      margin-right px--lg(270)

      +mq(sp)
        margin-right 0

    &__title
      fz--lg(4)

      +mq(sp)
        fz(1.8)

    &__text
      fz--lg(2.4)
      margin-top px--lg(30)

      +mq(sp)
        fz(1.4)
        margin-top 20px

    &__btn
      width px--lg(200)
      border 2px solid black_01
      border-radius 10px
      margin px--lg(45) auto 0
      transition all .3s

      +mq(sp)
        margin-top 30px

      a
        fz--lg(2.5)
        text-align center
        display block
        transition all .3s

        +mq(sp)
          fz(1.4)

      &:hover
        background-color black_01

        a
          color white_01
