/**
 * show
 */
bd = mq_config.bd

if (bd == "sp")
  .show--sp
    +mq(tab)
      display none

  .show--tab
    display none

    +mq(tab)
      display block

    +mq(pc)
      display none

  .show--pc
    display none

    +mq(pc)
      display block

else if (bd == "pc")
  .show--pc
    +mq(tab)
      display none

  .show--tab
    display none

    +mq(tab)
      display block

    +mq(sp)
      display none

  .show--sp
    display none

    +mq(sp)
      display block

else
  background-color red
