@import "./_common"

/**
 * トップページ
 */
body.home main
  .mv
    height px--lg(1000)
    bg("home/mv_01.jpg")
    padding px--lg(340) 0

    +mq(sp)
      height auto
      min-height 100vh
      max-height 100vh
      padding calc(calc(100vh - 115px) / 2) 0

    &__lead
      max-width px--lg(820)
      margin 0 auto

      +mq(sp)
        max-width 345px

      #svg-animation
        .cls-1, .cls-2
          fill none
          stroke #fff
          stroke-width 20
          stroke-linecap round
          stroke-linejoin round
          stroke-miterlimit 10
          stroke-dasharray 1500
          stroke-dashoffset 1500
  // --mv

  .msg
    text-align center
    margin-top px--lg(70)

    +mq(sp)
      padding 0 15px
      margin-top 45px

    &__title
      line-height 1
      fz--lg(5)
      display inline-block
      position relative

      +mq(sp)
        line-height 1.5
        fz(2.4)

      &::after
        content ""
        width px--lg(1385)
        height px--lg(303)
        bg("common/img_deco_01.png", false)
        position absolute
        bottom px--lg(50)
        right px--lg(-125)
        transform translateY(100%)
        z-index -10

        +mq(sp)
          width 345px
          height 75px
          bottom auto
          right auto
          top 50%
          left 30%
          transform translate(-50%, -50%)

    &__text
      fz--lg(2.5)
      margin-top px--lg(50)

      +mq(sp)
        fz(1.4)
        text-align left
        margin-top 30px

    &__video
      max-width px--lg(1080)
      height px--lg(640)
      margin px--lg(70) auto 0

      +mq(sp)
        max-width 345px
        height 205px
        margin-top 45px
  // --msg

  .partner
    margin-top px--lg(150)

    +mq(sp)
      margin-top 100px

    .ml_visual
      setBg("home/bg_partner_01.jpg")
  // --partner

  .power
    margin-top px--lg(95)

    +mq(sp)
      margin-top 60px

    .ml_visual
      setBg("home/bg_power_01.jpg")
  // --power

  .life_work
    margin-top px--lg(70)

    +mq(sp)
      margin-top 45px

    .ml_visual
      setBg("home/bg_life_work_01.jpg")
  // --life_work

  .news
    text-align center
    margin-top px--lg(170)

    +mq(sp)
      max-width 345px
      margin 110px auto 0

    &__title
      font-family skia_font
      fz--lg(5)

      +mq(sp)
        fz(2.4)

    &__list
      max-width px--lg(1120)
      text-align left
      margin px--lg(45) auto 0

      +mq(sp)
        margin-top 20px

      li
        border-top 1px solid black_01

        &:last-child
          border-bottom 1px solid black_01

        a
          fz--lg(1.7)
          display block
          padding px--lg(30) 0
          transition all .3s

          +mq(sp)
            fz(1.2)
            padding 20px 0

          &:hover
            color violet_01

    &__btn
      display inline-block
      margin-top px--lg(25)

      +mq(sp)
        margin-top 15px

      a
        line-height 1
        fz--lg(2.5)
        transition all .3s

        +mq(sp)
          fz(1.4)

        &:hover
          color violet_01
  // --news

  .for_kids
    margin-top px--lg(75)

    +mq(sp)
      margin-top 50px

    .ml_visual
      setBg("home/bg_for_kids_01.jpg")

      +mq(sp)
        background-position 58% center

      &__lead
        font-family skia_font
  // --for_kids
// --home
