/**
 * variables
 */
hm_width = 40px
hm_height = 40px
hm_thickness = 7px
hm_color = black_01
hm_dist = ((hm_height - hm_thickness) / 2)

/**
 * styles
 */
.hm
  width hm_width
  height hm_height
  flex(ver)
  // overflow hidden
  cursor pointer

  *
    width 100%
    height hm_thickness
    background-color hm_color
    // border-radius hm_thickness / 2
    display block
    transition all .3s

/**
 * animations
 */
.hm--open
  *:nth-child(1)
    transform translateY(hm_dist) rotate(45deg)

  *:nth-child(2)
    opacity 0

  *:nth-child(3)
    transform translateY((- hm_dist)) rotate(-45deg)
